<template>
  <ModalDrawer
    visible
    centered
    hide-footer
    :hide-header-close="isWebView"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    :title="computedTitle"
    @hide="emit('hide')"
  >
    <p>{{ computedDescription }}</p>

    <div class="text-right mt-4">
      <ZButton
        variant="highlight"
        @click="handleSubmit"
      >
        {{ t('buttonLabel') }}
      </ZButton>
    </div>
  </ModalDrawer>
</template>

<script setup lang="ts">
const props = defineProps<{
  errorCode?: string
  bookingId: number
}>()

const emit = defineEmits<{
  hide: []
}>()

const { t } = useI18n()
const { isWebView } = usePlatform()

const hasOverlappingDates = computed(() => {
  return props.errorCode === 'BOOKING_OVERLAPS_DATES'
})

const computedTitle = computed(() => {
  if (hasOverlappingDates.value) {
    return t('OverlappingDates.title')
  }

  return t('GenericError.title')
})

const computedDescription = computed(() => {
  if (hasOverlappingDates.value) {
    return t('OverlappingDates.description')
  }

  return t('GenericError.description')
})

const computedMessage = computed(() => {
  if (hasOverlappingDates.value) {
    return t('OverlappingDates.message')
  }

  return ''
})

const {
  changeRequestSuccess,
  declineChangeRequest,
  resetChangeRequest,
} = useChangeRequest()

async function handleSubmit() {
  await declineChangeRequest(props.bookingId, computedMessage.value)

  if (changeRequestSuccess.value) {
    await refreshNuxtData()
    emit('hide')
    resetChangeRequest()
  }
}
</script>

<i18n lang="json">
{
  "en": {
    "buttonLabel": "Got it!",
    "OverlappingDates": {
      "title": "This change request has overlapping dates",
      "description": "The requested changes overlap with another booking. The system will not allow for the requested changes to be applied. We will send a message to your guest to inform them.",
      "message": "Due to overlapping dates, the system declined this change request."
    },
    "GenericError": {
      "title": "There was an issue with this change request",
      "description": "The requested changes overlap with blocked or unavailable dates. The system will not allow for the requested changes to be applied. Please review your calendar or availability. We will send a message to your guest to inform them.",
      "message": "The requested changes overlap with blocked or unavailable dates. The system will not allow for the requested changes to be applied."
    }
  },
  "fr": {
    "buttonLabel": "Compris!",
    "OverlappingDates": {
      "title": "Cette demande de modification a des dates en chevauchement",
      "description": "Les modifications demandées chevauchent une autre réservation. Le système ne permettra pas l’application des modifications demandées. Nous enverrons un message à votre invité pour l’informer.",
      "message": "En raison de dates en chevauchement, le système a refusé cette demande de modification."
    },
    "GenericError": {
      "title": "Il y a eu un problème avec cette demande de modification",
      "description": "Les modifications demandées chevauchent des dates bloquées ou non disponibles. Le système ne permettra pas l’application des modifications demandées. Veuillez vérifier votre calendrier ou votre disponibilité. Nous enverrons un message à votre invité pour l’informer.",
      "message": "Les modifications demandées chevauchent des dates bloquées ou non disponibles. Le système ne permettra pas l’application des modifications demandées."
    }
  }
}
</i18n>
